*, html {
  scroll-behavior: smooth !important;
}

body::-webkit-scrollbar {
  background-color: #000000e6;
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-thumb {
  background: #fafafa80;
}

body, html {
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

nav .logo {
  float: left;
  margin-left: 30px;
}

nav .logo a {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: .5px;
  font-size: 28px;
  line-height: 80px;
  text-decoration: none;
}

nav .logo em {
  font-style: normal;
  font-weight: 200;
}

nav {
  z-index: 300;
  background: #fafafa33;
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  box-shadow: 0 2px 10px #00000080;
}

.main-nav a img {
  filter: invert();
}

#video-container {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

#video-container video, .video-overlay {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#video-container .video-overlay {
  z-index: 9999;
  background: #00000080;
  width: 100%;
}

#video-container .video-content {
  z-index: 99999;
  width: 100%;
  height: 100%;
  position: absolute;
}

#video-container .video-content .inner {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

#video-container .video-content .inner p {
  color: #fff;
  letter-spacing: 1.5px;
  text-align: center;
  padding: 0 30px;
  font-size: 22px;
  font-weight: 200;
}

#video-container .video-content .inner a {
  color: #fff;
  text-decoration: underline;
}

#video-container .video-content .inner .scroll-icon {
  margin-top: 45px;
}

.full-screen-portfolio .container-fluid, .full-screen-portfolio .col-md-4, .col-md-8 {
  padding-left: 0;
  padding-right: 0;
}

.portfolio-item img {
  width: 100%;
  overflow: hidden;
}

.full-screen-portfolio .portfolio-item h2 {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: .5px;
  background-color: #000;
  padding: 5px 5px 5px 20px;
  font-size: 22px;
  line-height: 15px;
  transition: all .5s ease-in-out;
  display: inline-block;
  position: relative;
  transform: translateY(25px);
}

.full-screen-portfolio .portfolio-item em {
  font-style: normal;
  font-weight: 200;
}

.full-screen-portfolio .portfolio-item:hover h2 {
  transform: translateY(0);
}

.full-screen-portfolio .portfolio-item p {
  letter-spacing: .5px;
  color: #fff;
  opacity: 0;
  text-transform: uppercase;
  background-color: #000;
  padding: 5px 5px 5px 20px;
  font-size: 14px;
  transition: all .5s ease-in-out;
  transform: translateY(10px);
  font-weight: 300 !important;
}

.full-screen-portfolio .portfolio-item {
  text-align: center;
  text-transform: lowercase;
  cursor: pointer;
  line-height: 150%;
}

.full-screen-portfolio .portfolio-item:hover p {
  opacity: 1;
  transform: translateY(0);
}

footer {
  background-color: #313131;
  width: 100%;
  height: 80px;
}

footer p {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .5px;
  padding-top: 33px;
  font-size: 13px;
  font-weight: 200;
}

.portfolio-item .hover-effect .hover-content {
  text-align: left;
  width: auto;
  position: absolute;
  bottom: 5px;
  left: 0;
}
/*# sourceMappingURL=index.6b4157b5.css.map */
